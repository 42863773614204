import React, { Suspense, useEffect, useState } from "react";
import { importComponent } from "common/loaders/importComponent";
import { useNavigate, useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import LoadingBox from "common/components/LoadingBox/LoadingBox";
import { useNidNodePage } from "modules/website/hooks/useNidNodePage";

export default function IframeView() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [importedComponent, setImportedComponent] = useState(null);
  const [otherAttributes, setOtherAttributes] = useState(null);

  const { isLoading, isError, payload, error } = useNidNodePage({ nid: id });

  useEffect(() => {
    const loadComponent = async () => {
      if (isLoading || !payload) return;
      const type = payload?.type;

      const componentMap = {
        event: "modules/website/views/ArticleView/ArticleView",
      };

      const specialAttributesMap = {
        event : {imgattribute:"field_event_main_image", bodyattribute: "field_event_body", type:"Event"},
      }

      const componentUri = componentMap[type] ?? null;
      const specialAttribute = specialAttributesMap[type] ?? specialAttributesMap.default;


      if (!componentUri) {
        console.warn("Component URL not found in ComponentMap");
        navigate("/not-found");
        return;
      }
      //   console.log(payload);
      setImportedComponent(componentUri);
      setOtherAttributes(specialAttribute);
    };
    loadComponent();
  }, [payload]);

  const LazyComponent = importedComponent !== null ? importComponent(importedComponent) : null;

  return (
    <Container className={`sm-layout-wrapper scrollSection bgGray p-0`} fluid>
      {isError && (
        <div class="alert alert-danger" role="alert">
          {error}
        </div>
      )}
      {LazyComponent !== null && !isError && (
        <Suspense fallback={<LoadingBox />}>
          <LazyComponent data={payload} toIframe={true} {...otherAttributes}/>
        </Suspense>
      )}
    </Container>
  );
}
