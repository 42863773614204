import React, { useState } from "react";
import { useGLTranslate } from "modules/website/hooks/useGLTranslate";
import { Form, InputGroup } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export default function OnlySearchBar() {
  const [Translate] = useGLTranslate();
  const [value, setValue] = useState("");
  const navigate = useNavigate();

  const handleSearch = (e) => {
    e.preventDefault();
    navigate(`/search/page?rows=12&keywords=${value}`);
  };

  return (
    <Form className={`d-flex w-100`} onSubmit={handleSearch}>
      <InputGroup>
        <Form.Control
          type="search"
          //placeholder={Translate("searchLabel")}
          aria-label={Translate("searchLabel")}
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        />
        <InputGroup.Text id="basic-addon1">
          <FaSearch />
        </InputGroup.Text>
      </InputGroup>
    </Form>
  );
}
