const rwText = {
  search: "Shakisha",
  searchLabel: "Urifuza kumenya iki?",
  lSelect: "Hitamo Ururimi",
  login: "Injira",
  ulinks: "Izindi mbuga",
  termscontitios: "Terms & Conditions",
  privpolicy: "Privacity Policy",
  learnmore: "Menya Ibirenzeho",
  getInvolved: "UKO WATANGA UMUSANZU",
  involvedText:
    "Tugezeho inkuru yawe, udusangize ibyo wagezeho mu kubaka amahoro, uraba ufashije abandi gusobanukirwa ubumuntu binyunze mu burezi bugamije kubaka amahoro n’indangagaciro.",
  stayInTouch: "GUMANA NA TWE",
  stayInTouchText:
    "Iyandikishe kugira ngo wakire ibaruwa mbarankuru yacu umenye amakuru yose agezweho.",
  joinNewsletter: "IYANDIKISHE KU KWAKIRA IBARUWA MBARANKURU YACU",
  userFeedbackForm: "IFISHI YAKIRA IBITEKEREZO",
  userFeedbackFormText: "Turifuza kumenya uko wabonye uru rubuga.",
  feedBackForm: "IFISHI YAKIRA IBITEKEREZO",
  teacherActivities: "IBIKORWA BY'ABARENGANZIRA",
  standardAddreses: "IMIGAMBI YASHYIZWEHO",
  learnerActivities: "IBIKORWA BY'ABIGA",
  competiences: "UBUSHOBZI",
  crossCuttingIssues: "IBIBAZO BIKORA KU MASHAMI",
  shareThis: "Sangiza ibi",
  downloadThisArticle: "Kanda ugumane iyi nyandiko",
  titleOfTheLesson: "IZINA RY'ISOMO",
  instructionalObjective: "INTANDARO Y'IKIGAMBO",
  planForThisClass: "GATEGANYIRIZO KU ISOMO RY'IKI GIHE",
  materials: "IBIKORESHO",
  references: "Amakuru",
  originalFileName: "Izina ry'inyandiko y'ibanze",
  size: "Ingano",
  mimeType: "Ubwoko bwa MIME",
  author: "Umwanditsi",
  format: "Isura",
  typeOfMedia: "Ubwoko bw'itangazamakuru",
  interviewees: "Ababajijwe",
  interviewer: "Umubaza",
  language: "Ururimi",
  targetAudiences: "abakurikira intego",
  quizz: "Ikizamini",
  quizzes: "Ibizamini",
  description: "Ibisobanuro",
  questions: "Ibibazo",
  selectStartEndTime:
    "Hitamo igihe gitangira n’igihe kirangira kugira ngo ukore igice cya videwo",
  pageNotFoundTitle: "Paji Ntibabonetse",
  pageNotFoundText: "Duhangayikishije, paji ushaka ntiyabashije kuboneka.",
  pageNotFoundText2: "Nyamuneka subira ku rupapuro rw'ibanze",
  peaceClub: "Ishyirahamwe ry’Amahoro",
  popularArticles: "Amakuru y'ingenzi",
  category: "Ibyiciro",
  catNews: "Amakuru",
  catEvents: "Ibikorwa",
  catPeaceStories: "Inkuru z'Amahoro",
  catOther: "n'ibindi",
};

export default rwText;
