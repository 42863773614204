import React, { lazy, Suspense } from "react";
import SideMenuTopBarLayout from "common/layouts/SideMenuTopBarLayout/SideMenuTopBarLayout";
import { Navigate } from "react-router-dom";
import FullPageSpinner from "common/components/FullPageSpinner/FullPageSpinner";
import TranslatorContainer from "common/TranslatorContainer/TranslatorContainer";
import SearchTollkitView from "modules/website/views/SearchTollkitView/SearchTollkitView";
import SearchView from "modules/website/views/SearchView/SearchView";
import IframeView from "modules/website/layouts/IframeView/IframeView";
import EventView from "modules/website/layouts/EventView/EventView";

const AliasNodeView = lazy(() =>
  import("modules/website/layouts/AliasNodeView/AliasNodeView")
);
const NidNodeView = lazy(() =>
  import("modules/website/layouts/NidNodeView/NidNodeView")
);
const HomePage = lazy(() => import("modules/website/views/HomePage/HomePage"));
const NotFound = lazy(() => import("views/NotFound/NotFound"));

const DefaultRoutes = [
  {
    path: `/404`,
    element: <Navigate to="/not-found" replace />,
  },
  {
    path: `/`,
    element: (
      <Suspense fallback={<FullPageSpinner />}>
        <TranslatorContainer>
          <SideMenuTopBarLayout variant="dark">
            <HomePage />
          </SideMenuTopBarLayout>
        </TranslatorContainer>
      </Suspense>
    ),
  },
  {
    path: `/search/page/`,
    element: (
      <Suspense fallback={<FullPageSpinner />}>
        <TranslatorContainer>
          <SideMenuTopBarLayout variant="alternative">
            <SearchView />
          </SideMenuTopBarLayout>
        </TranslatorContainer>
      </Suspense>
    ),
  },
  {
    path: `/search/page/:toolkit`,
    element: (
      <Suspense fallback={<FullPageSpinner />}>
        <TranslatorContainer>
          <SideMenuTopBarLayout variant="alternative">
            <SearchTollkitView />
          </SideMenuTopBarLayout>
        </TranslatorContainer>
      </Suspense>
    ),
  },
  {
    path: `/node/:id`,
    element: (
      <Suspense fallback={<FullPageSpinner />}>
        <TranslatorContainer>
          <NidNodeView />
        </TranslatorContainer>
      </Suspense>
    ),
  },
  {
    path: `/iframe/:id`,
    element: (
      <Suspense fallback={<FullPageSpinner />}>
        <TranslatorContainer>
          <IframeView />
        </TranslatorContainer>
      </Suspense>
    ),
  },
  {
    path: `/event/:id`,
    element: (
      <Suspense fallback={<FullPageSpinner />}>
        <TranslatorContainer>
          <EventView />
        </TranslatorContainer>
      </Suspense>
    ),
  },
  {
    path: `/:alias`,
    element: (
      <Suspense fallback={<FullPageSpinner />}>
        <TranslatorContainer>
          <AliasNodeView />
        </TranslatorContainer>
      </Suspense>
    ),
  },
  {
    path: `*`,
    element: <Navigate to="/not-found" replace />,
  },
  {
    path: `/not-found`,
    element: (
      <Suspense fallback={<FullPageSpinner />}>
        <NotFound />
      </Suspense>
    ),
  },
];

export default DefaultRoutes;
